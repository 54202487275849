export const navItems = [
  { name: "About", link: "#about" },
  { name: "Projects", link: "#projects" },
  { name: "Testimonials", link: "#testimonials" },
  { name: "Contact", link: "#contact" },
];

export const gridItems = [
  {
    id: 1,
    title: "I make working closely with clients & encouraging clear communication a priority ",
    description: "",
    className: "lg:col-span-3 md:col-span-6 md:row-span-4 lg:min-h-[60vh]",
    imgClassName: "w-full h-full",
    titleClassName: "justify-end",
    img: "/b1.svg",
    spareImg: "",
  },
  {
    id: 2,
    title: "I'm very flexible with time zone communications",
    description: "",
    className: "lg:col-span-2 md:col-span-3 md:row-span-2",
    imgClassName: "",
    titleClassName: "justify-start",
    img: "",
    spareImg: "",
  },
  {
    id: 3,
    title: "My tech stack",
    description: "I constantly try to improve",
    className: "lg:col-span-2 md:col-span-3 md:row-span-2",
    imgClassName: "",
    titleClassName: "justify-center",
    img: "",
    spareImg: "",
  },
  {
    id: 4,
    title: "Machine learning enthusiast with a passion for software development.",
    description: "",
    className: "lg:col-span-2 md:col-span-3 md:row-span-1",
    imgClassName: "",
    titleClassName: "justify-start",
    img: "/grid.svg",
    spareImg: "/b4.svg",
  },

  {
    id: 5,
    title: "Currently building a full stack social media app ",
    description: "The Inside Scoop",
    className: "md:col-span-3 md:row-span-2",
    imgClassName: "absolute right-0 bottom-0 md:w-96 w-60",
    titleClassName: "justify-center md:justify-start lg:justify-center",
    img: "/b55.svg",
    spareImg: "/grid.svg",
  },
  {
    id: 6,
    title: "Do you want to start a project together?",
    description: "",
    className: "lg:col-span-2 md:col-span-3 md:row-span-1",
    imgClassName: "",
    titleClassName: "justify-center md:max-w-full max-w-60 text-center",
    img: "",
    spareImg: "",
  },
];

export const projects = [
  {
    id: 1,
    title: "Social Media Application",
    des: "Explore the wonders of our social media app with a captivating Ui, infinite scroll feature and amazing perfomance using React JS, Appwrite and more.",
    img: "/snapgram.svg",
    iconLists: ["/re.svg", "/tail.svg", "/ts.svg", "/vite.svg"],
    link: "https://momentsgram-scd1.vercel.app/",
  },
  {
    id: 2,
    title: "Healthcare management system",
    des: "A healthcare platform that streamlines patient registration, appointment scheduling, medical records, SMS notifications with Admin",
    img: "/carepulse.jpg",
    iconLists: ["/next.svg", "/tail.svg", "/ts.svg", "/three.svg", ],
    link: "https://ao-carepulse.vercel.app/",
  },

 
  {
    id: 3,
    title: "Metagame Website",
    des: "A unified play layer that bridges players, agentic AI, and blockchains, opening a new world of AI-driven gamification",
    img: "/zintrix.png",
    iconLists: ["/vite.svg", "/react.svg",],
    link: "https://zintrix.adeolaokubadejo.com/",
  },
   /*
  {
    id: 4,
    title: "Animated Apple Iphone 3D Website",
    des: "Recreated the Apple iPhone 15 Pro website, combining GSAP animations and Three.js 3D effects..",
    img: "/p4.svg",
    iconLists: ["/next.svg", "/tail.svg", "/ts.svg", "/three.svg", "/gsap.svg"],
    link: "https://github.com/adrianhajdin/iphone",
  },
  */
];

export const testimonials = [
  {
    quote:
      "Working with Adeola was a sheer delight. Her professionalism, promptness, and dedication to delivering exceptional results were evident throughout our project. Adeola's enthusiasm for every facet of development truly stands out. If you're seeking to elevate your website and elevate your brand, Adeola is the ideal partner.",
    name: "Mr Afolabi",
    title: "CEO of SAO ventures",
  },
  {
    quote:
      "Her problem solving skills and attention to details were exceptional. She debugs like a pro! Collaborating with her on my nextJs and python projects was a wholesome experience",
    name: "Mr Tola",
    title: "Director of Rydell Technologies",
  },
  {
    quote:
      "Highly recommend Adeola's service to anyone looking for topnotch technical solutions. Her ability to navigate complex challenges with ease exceeded my expectation. I would gladly work with her again in future",
    name: "Mr. Frank",
    title: "Aptech",
  },
  {
    quote:
      "Working with Adeola was a sheer delight. Her professionalism, promptness, and dedication to delivering exceptional results were evident throughout our project. Adeola's enthusiasm for every facet of development truly stands out. If you're seeking to elevate your website and elevate your brand, Adeola is the ideal partner.",
    name: "Mr. Afolabi",
    title: "CEO of SAO ventures",
  },
  {
    quote:
      "Her problem solving skills and attention to details were exceptional. She debugs like a pro! Collaborating with her on my nextJs and python projects was a wholesome experience",
    name: "Mr Tola",
    title: "Director of Rydell Technologies",
  },
];

/*
export const companies = [
  {
    id: 1,
    name: "cloudinary",
    img: "/cloud.svg",
    nameImg: "/cloudName.svg",
  },
  {
    id: 2,
    name: "appwrite",
    img: "/app.svg",
    nameImg: "/appName.svg",
  },
  {
    id: 3,
    name: "HOSTINGER",
    img: "/host.svg",
    nameImg: "/hostName.svg",
  },
  {
    id: 4,
    name: "stream",
    img: "/s.svg",
    nameImg: "/streamName.svg",
  },
  {
    id: 5,
    name: "docker.",
    img: "/dock.svg",
    nameImg: "/dockerName.svg",
  },
];
*/

export const workExperience = [
  {
    id: 1,
    title: "Junior Software Engineer",
    desc: "Collaborated and worked closely with senior software engineers to ensure the successful deployment of applications.",
    className: "md:col-span-2",
    thumbnail: "/exp1.svg",
  },
  {
    id: 2,
    title: "Mobile App Dev - Rydell Tech",
    desc: "Designed and developed mobile app for both iOS & Android platforms using React Native.",
    className: "md:col-span-2", // change to md:col-span-2
    thumbnail: "/exp2.svg",
  },
  {
    id: 3,
    title: "Freelance App Dev Project",
    desc: "Led the dev of a mobile app for a client, from initial concept to deployment on app stores.",
    className: "md:col-span-2", // change to md:col-span-2
    thumbnail: "/exp3.svg",
  },
  {
    id: 4,
    title: "Backend Developer",
    desc: "Managed server-side functionalities using modern backend technologies to power seamless user experience.",
    className: "md:col-span-2",
    thumbnail: "/exp4.svg",
  },
];

export const socialMedia = [
  {
    id: 1,
    img: "/git.svg",
  },
  {
    id: 2,
    img: "/twit.svg",
  },
  {
    id: 3,
    img: "/link.svg",
  },
];